.about-container {
  background-color: #fe4a49;
  color: #fff;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 40px 64px 100px 64px;
  width: 100%;
  margin: 0 auto;
}

.about-container > h1 {
  font-size: 32px;
  margin-bottom: 30px;
}

.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.about-img {
  width: 40%;
  padding: 20px;
}

.about-img1 > img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.about-img2 > img {
  width: 10%;
  height: 40%;
  object-fit: cover;
  position: absolute;
  top: 65%;
  right: 65%;
}

.about-text {
  width: 50%;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .about-container > h1 {
    font-size: 40px;
  }

  .about-img1 > img {
    width: 80%;
    height: 80%;
    object-fit: cover;
    margin: 0 10%;
  }

  .about-img2 > img {
    width: 32%;
    height: 15%;
    object-fit: cover;
    position: absolute;
    top: 30%;
    right: 10%;
  }

  .about-content {
    flex-direction: column;
  }

  .about-img,
  .about-text {
    width: 100%;
  }

  .about-text p {
    font-size: 16px;
  }
  .about-img {
    height: 300px;
  }

  .about-text {
    font-size: 15px;
  }
}
