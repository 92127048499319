.contact-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-color: #fbf3aa;
}
p {
  margin: 20px;
  font-weight: 400;
  font-size: 18px;
}

.contact-btns {
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .contact-container {
    font-size: 16px;
    padding: 84px;
  }

  .contact-container p {
    text-align: center;
  }

  .btn--large {
    margin: 10px;
    font-size: 15px;
  }
}
