:root {
  --primary: #fff;
}

.btn {
  padding: 10px 30px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.btn--primary {
  background-color: black;
  color: white;

  padding: 10px 30px;
  text-decoration: none;
}

.btn--outline {
  background-color: #fbf3aa;
  color: black;
  padding: 10px 30px;
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 15px 30px;
  font-size: 15px;
}

.btn--large {
  padding: 15px 30px;
  font-size: 20px;
}

.btn--large:hover {
  transition: all 0.3s ease-out;
  background: white;
  color: #242424;
  transition: 250ms;
}
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: white;
  color: #242424;
  transition: 250ms;
}

.btn a {
  text-decoration: none;
  color: white;
}

.btn a:hover {
  text-decoration: none;
  color: black;
}
