.project-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.project-hero {
  width: 100%;
  height: 400px;
  margin-bottom: 40px;
}

.project-hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 16px;
  text-align: center;
}

.project-year {
  font-size: 18px;
  font-weight: 400;
  color: #808080;
  margin-bottom: 24px;
}

.projects-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
}

.description-title h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}

.project-description {
  font-size: 18px;
  line-height: 1.5;
  color: #808080;
  margin-bottom: 40px;
}

img {
  max-width: 100%;
  height: auto;
}

.text-container,
.project-content {
  padding: 64px;
}

@media screen and (max-width: 768px) {
  .project-hero {
    height: 250px;
  }

  .project-title {
    font-size: 24px;
  }

  .project-year {
    font-size: 16px;
  }

  .project-content,
  .text-container {
    padding: 56px;
  }

  .projects-card {
    padding: 20px;
    margin: 0 64px;
  }

  .description-title h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .project-description {
    font-size: 16px;
    margin-bottom: 24px;
  }
}
